import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_tenant } from '@public/stores/tenant_store'

export const use_public_actor = defineStore('public_actor', {
  state: () => ({
    is_employee: null,
    is_logged_in: null,
    current_user: null,
    delete_account_url: null,

    customer: null,
    customer_participants: [],
  }),

  actions: {
    async delete_customer_participant(participant) {
      const url = `${use_tenant().api_home_url}/checkins/customer-participants/${participant.id}`
      await Vue.smcb_axios.delete(url, participant)
      Vue.delete(
        this.customer_participants,
        this.customer_participants.findIndex(cp => cp.id === participant.id)
      )
    },

    set_customer_participant(participant) {
      Vue.set(
        this.customer_participants,
        this.customer_participants.findIndex(cp => cp.id === participant.id),
        participant
      )
    },

    async update_customer_participant(participant) {
      const url = `${use_tenant().api_home_url}/checkins/customer-participants/${participant.id}`
      const { data } = await Vue.smcb_axios.patch(url, participant)
      this.set_customer_participant(data)
    },

    async load_customer_data() {
      // const url = `${use_tenant().api_url}/customer` TODO: NQ-3101 Unify on wilma
      const url = `${use_tenant().api_home_url}/checkins/public-slots/customer`
      const { data } = await Vue.smcb_axios.get(url)
      this.customer = data.customer
      this.customer_participants = data.participants
    },
  },
})
