import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_octopus_tentacles } from '@public/stores/octopus_tentacles_store'
import { use_tenant } from '@public/stores/tenant_store'

export const use_octopus_brain = defineStore('octopus_brain', {
  state: () => ({
    integration_objects: {},
    slot_areas: {},
    slot_def: null,
    slot_defs_by_day: { loading: true },
    selected_service_id: null,
    services: [],
    service_settings: {},
    searching_area: null,
    searching_service_name: null,
  }),

  actions: {
    async set_up_public_octopus_search_stores() {
      const use = {
        tenant: use_tenant(),
        tentacles: use_octopus_tentacles(),
      }

      // SMCB SETUP
      const { data } = await Vue.smcb_axios.get(`${use.tenant.api_home_url}/checkins/public-octopus-search`)

      use.tenant.home = data.gym
      use.tenant.settings = data.gym_settings
      this.selected_service_id = data.service_id
      this.services = data.services
      data.slot_areas.map(slot_area => Vue.set(this.slot_areas, slot_area.id, slot_area))
      this.integration_objects = data.integration_objects
      this.service_settings = data.service_settings

      // Fetch Slot Definitions
      try {
        const days = data.service_settings?.days_in_advance
        await this.fetch_future_slot_defs({ days })
      } catch (e) {
        console.error('VL could not load Slot Definitions for date range', e)
      }

      // TRIGGER CLUSTER SETUP
      const tentacles_keys = this.get_octopus_brain_io_select('octopus_search_link_tentacles')
      try {
        await Promise.all(
          tentacles_keys.map(key => {
            const [vl_gym_id, slug] = key.split(':')
            return use.tentacles.setup_octopus_tentacle({ vl_gym_id, slug })
          })
        )
      } catch (e) {
        console.error('VL octopus_search_link_tentacles param is incorrectly configured!', e)
      }
    },

    //
    // Changed the currently selected date on the public checkins, reset search accordingly
    //
    change_selected_date_on_octopus_brain({ date }) {
      const slot_defs = this.get_slot_defs_by_day[date.toFormat('yyyy-MM-dd')]
      const slot_def = slot_defs && slot_defs[0]
      this.slot_def = slot_def || { missing: true }
    },

    //
    // Fetch all Slot Definitions for all possible bookable days
    //
    async fetch_future_slot_defs({ days }) {
      const today = Vue.$vl_time.get_today()
      const date_path = today.toFormat('yyyy/MM/dd')
      const url = `${use_tenant().api_home_url}/services/${this.selected_service_id}/checkins/public-slots/defs-from/${date_path}/for/${days}/days`
      const { data } = await Vue.smcb_axios.get(url)
      this.slot_defs_by_day = data.slot_defs
    },
  },

  getters: {
    get_octopus_brain_io: state => key => {
      return state.integration_objects[key]
    },

    get_octopus_brain_io_select: state => key => {
      const io = state.integration_objects[key]
      if (!io) return null
      return io.data.list
    },

    get_octopus_brain_io_richtext: state => key => {
      const io = state.integration_objects[key]
      if (!io) return null
      return io.data[I18n.locale]
    },

    active_slot_areas: state =>
      Object.values(state.slot_areas)
        .filter(x => x.archived_at === null)
        .sort((a, b) => a.order - b.order),

    get_octopus_brain_checkin_settings: (state, getters) => {
      const settings = state.service_settings
      return { days_in_advance: settings.days_in_advance, max_people_booking: settings.max_people_booking }
    },

    //
    // SLOT DEFINITIONS
    //

    // Get all Slot Definitions index by date
    get_slot_defs_by_day: state => (state.slot_defs_by_day.loading ? {} : state.slot_defs_by_day),

    // True when all Slot Definitions of the date range have been loaded
    slot_defs_have_been_loaded: state => !state.slot_defs_by_day.loading,

    // Get all days for which there are Slot Definitions define
    list_valid_slot_def_days() {
      if (!this.slot_defs_have_been_loaded) return []
      return Object.entries(this.get_slot_defs_by_day)
        .filter(([k, v]) => v)
        .map(([k, v]) => k)
        .sort()
    },

    // Calculate the first available date with a valid Slot Definition
    get_octopus_brain_first_available_date() {
      const days = this.list_valid_slot_def_days
      if (days.length === 0) return null
      return Vue.$vl_time.parse_as_day(days[0])
    },
  },
})
