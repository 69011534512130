import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_tenant } from '@public/stores/tenant_store'

export const use_first_available_slots = defineStore('first_available_slots', {
  state: () => ({
    loading: false,
    first_available: null,
    first_available_on_time_range: null,
  }),

  actions: {
    async load() {
      // TODO: [NQ-1813] Avoid race conditions

      const params = new URLSearchParams(window.location.search)
      const private_code = params.get('private_code')

      this.loading = true
      const url = `${use_tenant().api_home_url}/checkins/first-available`
      const { data } = await Vue.smcb_axios.get(url, { params: { private_code } })
      this.first_available = data
      this.first_available_on_time_range = data
      this.loading = false
    },

    async clear_first_available_slots_on_time_range() {
      this.first_available_on_time_range = this.first_available
    },

    async filter_first_available_slots_on_time_range({ fr, to }) {
      // TODO: [NQ-1813] Avoid race conditions

      this.loading = true
      const url = `${use_tenant().api_home_url}/checkins/first-available`
      const { data } = await Vue.smcb_axios.get(url, { params: { fr, to } })
      this.first_available_on_time_range = data
      this.loading = false
    },
  },
})
