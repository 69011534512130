import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_public_bookings } from '@public/stores/public_bookings_store'
import { use_public_recurring_tasks } from '@public/stores/public_recurring_tasks_store'
import { use_public_task_groups } from '@public/stores/public_task_groups_store'
import { use_tenant } from '@public/stores/tenant_store'

function parse_task(task) {
  return Object.freeze({
    ...task,
    start: Vue.$vl_time.parse_as_local(task.start),
    end: task.end ? Vue.$vl_time.parse_as_local(task.end) : null,
    allDay: task.is_all_day,
    bookable_from: task.bookable_from ? Vue.$vl_time.parse_as_local(task.bookable_from) : null
  })
}

export const use_public_tasks = defineStore('public_tasks', {
  state: () => ({
    all: [],
    loaded_tasks_urls: [],
    current_id: null,
  }),

  actions: {
    add(tasks) {
      const oldTasks = this.all
      const newTasks = tasks.filter(t => !oldTasks.some(u => u.id === t.id)).map(parse_task)
      this.all.push(...newTasks)
    },

    async load({ start, end }) {
      const urls = new Set()
      for (let i = 0; start.plus({ months: i }).startOf('month') < end; i++) {
        const month = start.plus({ months: i })
        const url = `${use_tenant().api_home_url}/public/tasks/${month.year}/${month.month}`
        if (!this.loaded_tasks_urls.includes(url)) {
          this.loaded_tasks_urls.push(url)
          urls.add(url)
        }
      }
      if (urls.size > 0) {
        return Promise.all(
          [...urls].map(url =>
            Vue.smcb_axios.get(url).then(response => {
              const data = response.data
              this.add(data.tasks || [])
              use_public_recurring_tasks().add(data.recurring_tasks || [])
              use_public_bookings().add_anonymous_bookings(data.anonymous_bookings)
            })
          )
        )
          .then(() => {
            return this.active
          })
          .catch(error => {
            return error
          })
      } else {
        return Promise.resolve(this.active)
      }
    },

    async load_from_single_task(task_id) {
      Vue.smcb_axios
        .get(`${use_tenant().api_home_url}/public/tasks_by_single_task/${task_id}`)
        .then(response => {
          const data = response.data
          this.add(data.tasks || [])
          use_public_recurring_tasks().add(data.recurring_tasks || [])
          use_public_bookings().add_anonymous_bookings(data.anonymous_bookings)
        })
        .catch(error => {
          console.log(error)
        })
      return this.active.find(t => t.id === task_id)
    },
  },

  getters: {
    from_id: state => id => state.all.find(t => t.id === id),

    active: state =>
      state.all
        .filter(t => !t.archived_at)
        .map(task => {
          const task_group = use_public_task_groups().from_id(task.task_group_id)
          return { ...task, title: task_group ? task_group.name : '' }
        }),

    task_groups_in_use(state) {
      return state.all
        .filter(task => task.public && !task.archived_at)
        .filter(task => {
          const tg = use_public_task_groups().from_id(task.task_group_id)
          return tg?.show_on_calendar
        })
        .reduce((r, task) => ({ ...r, [task.task_group_id]: true }), {})
    },
  },
})
