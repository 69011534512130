import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_public_actor } from '@public/stores/public_actor_store'
import { use_tenant } from '@public/stores/tenant_store'

// TODO: NQ-3101 unify (every time that service settings changes)
function hide_top_menu_login_widget(service_settings) {
  const dom = document.getElementById('top_menu_login_widget')
  if (service_settings.hide_login_link) {
    if (dom) dom.style.display = 'none'
  } else {
    if (dom) dom.style.display = 'block'
  }
}

function parse_slot(slot) {
  slot.check_in_at = Vue.$vl_time.parse_as_local(slot.check_in_at)
  return slot
}

export const use_public_slots = defineStore('public_slots', {
  state: () => ({
    claimed_spots_count: null,
    consent_document_url: null,
    current_day: null,
    current_slot_area: {},
    current_slot_defs: null,
    booking_checkboxes: [],
    days_in_advance_by_service: null,
    free_spots_per_slot: {},
    iframe_mode: false,
    participant_fields: [],
    populate_service_loading: null,
    recent_booking_data: null,
    selected_service_id: null,
    slot_areas_by_service: null,
    slot_areas: {},
    slot_defs_by_day: { loading: true },
    slot_to_book: {},
    slots: {},
  }),

  actions: {
    set_public_slots({ slots }) {
      slots.forEach(({ slot, def_id, free_spots, vduration }) => {
        // We inject the free_spots into the read-only slot object for convenience
        slot.free_spots = free_spots
        slot.slot_def_id = def_id
        slot.vduration = vduration
        // Parse the dates and store the object
        slot = parse_slot(slot)
        Vue.set(this.slots, slot.check_in_at, slot)
      })
    },

    set_slot_areas(slot_areas) {
      this.slot_areas = {}
      slot_areas.forEach(slot_area => {
        Vue.set(this.slot_areas, slot_area.id, slot_area)
      })
    },

    set_recent_booking_data({ booking, participants, payload, download_urls }) {
      this.recent_booking_data = { booking, participants, payload, download_urls: download_urls || null }
    },

    set_recent_booking_error({ code, message }) {
      this.recent_booking_data = { is_error: true, code, message }
    },

    async de_populate_service() {
      const use = { tenant: use_tenant() }
      use.tenant.contextual.service_settings = {}
      use.tenant.contextual.service_options = {}
      this.selected_service_id = null
      this.set_slot_areas([])
      this.current_slot_area = {}
      this.populate_service_loading = false
    },

    async populate_service({ service, router }) {
      if (router && router.currentRoute.path != `/service/${service.name}`) {
        const date = router.currentRoute.params.date
        router.push({
          name: 'checkins-find',
          params: {
            service_name: service.name,
            area_id: date ? '0' : null,
            date,
          },
          query: this.$route.query,
        })
      }

      // Update locally-maintained data with the new service pointer
      this.selected_service_id = service.id
      this.set_slot_areas(this.slot_areas_by_service[service.id] || [])
      this.current_slot_area = {}

      const use = { tenant: use_tenant() }

      // Reset state to loading
      use.tenant.contextual.service_settings = {}
      use.tenant.contextual.service_options = {}
      this.populate_service_loading = true

      const { data } = await Vue.smcb_axios.get(`${this.smcb_service_url}/checkins/populate-service`)

      hide_top_menu_login_widget(data.service_settings)
      use.tenant.contextual.service_settings = data.service_settings
      use.tenant.contextual.service_options = data.service_options
      this.booking_checkboxes = data.booking_checkboxes
      this.participant_fields = data.participant_fields
      this.populate_service_loading = false
    },

    //
    // Fetch all Slot Definitions for all possible bookable days
    //
    async fetch_future_slot_defs({ days }) {
      const today = Vue.$vl_time.get_today()
      const date_path = today.toFormat('yyyy/MM/dd')
      const url = `${this.smcb_service_url}/checkins/public-slots/defs-from/${date_path}/for/${days}/days`
      const { data } = await Vue.smcb_axios.get(url)
      this.slot_defs_by_day = data.slot_defs
      this.ensure_current_slot_defs()
    },

    //
    // Fetch all Slot Definitions for all possible bookable days on this area
    //
    async fetch_future_slot_defs_for_area({ days, slot_area_id }) {
      const today = Vue.$vl_time.get_today()
      const date_path = today.toFormat('yyyy/MM/dd')
      const url = `${this.smcb_service_url}/checkins/public-slots/area/${slot_area_id}/defs-from/${date_path}/for/${days}/days`
      const { data } = await Vue.smcb_axios.get(url)
      this.slot_defs_by_day = data.slot_defs
      this.ensure_current_slot_defs()
    },

    //
    // Make sure current Slot Definition is set
    //
    ensure_current_slot_defs() {
      if (this.current_day) {
        const date_str = this.current_day.toFormat('yyyy-MM-dd')
        this.current_slot_defs = this.get_slot_defs_by_day[date_str]
      }
    },

    //
    // Changed the currently selected date on the public checkins, reset search accordingly
    //
    change_selected_date_on_public_checkins({ date }) {
      this.slots = {}
      this.current_day = date

      const date_str = date.toFormat('yyyy-MM-dd')
      const slot_defs = this.get_slot_defs_by_day[date_str]
      if (slot_defs) {
        this.current_slot_defs = slot_defs
      } else {
        this.current_slot_defs = null
      }
    },

    //
    // Fetch Slot Definition for a single day
    //
    async fetch_slot_defs_for_date(date) {
      this.current_slot_defs = null
      this.current_day = date

      const date_path = date.toFormat('yyyy/MM/dd')
      const url = `${this.smcb_service_url}/checkins/public-slots/defs-for-date/${date_path}`
      const { data } = await Vue.smcb_axios.get(url)
      this.current_slot_defs = data.slot_defs
    },

    //
    // Fetch the area-owning Slot Definitions for a single day
    //
    async fetch_slot_defs_for_date_and_area({ date, slot_area_id }) {
      this.current_slot_defs = null
      this.current_day = date

      const date_path = date.toFormat('yyyy/MM/dd')
      const url = `${this.smcb_service_url}/checkins/public-slots/area/${slot_area_id}/defs-for-date/${date_path}`
      const { data } = await Vue.smcb_axios.get(url)
      this.current_slot_defs = data.slot_defs
    },

    async fetch_days_public_slots({ slot_area_id }) {
      // Overload with task token for private bookings if present
      const params = new URLSearchParams(window.location.search)
      const private_code = params.get('private_code')

      // Fetch the slots
      this.slots = {}
      const date_path = this.current_day.toFormat('yyyy/MM/dd')
      const ruled = this.selected_service.ruled ? 'area' : 'at'
      const url = `${this.smcb_service_url}/checkins/public-slots/${ruled}/${slot_area_id}/date/${date_path}`
      const { data } = await Vue.smcb_axios.get(url, { params: { private_code } })
      this.set_public_slots(data)
    },

    async save_public_checkins_booking(payload) {
      const referral = Vue.$cookies.get('vl_checkins_referral_source')
      if (referral) payload.referral_source = referral

      let url = `${this.smcb_service_url}/checkins/book`
      if (payload.combo_data) url += '?combo=true'

      try {
        const { data } = await Vue.smcb_axios.post(url, payload)

        // if payment is required, redirect to the checkout page with the proper redirect url
        if (payload.booking.ticket_required && data.payment_redirect_url && data.payment_redirect_url !== null) {
          window.location.href = `${data.payment_redirect_url}`
        } else {
          this.set_recent_booking_data({ ...data, payload })
        }
      } catch (e) {
        if (e.response.status === 409) {
          this.set_recent_booking_error({ code: 'schedule_config_changed' })
        } else if (e.response.status === 410) {
          this.set_recent_booking_error({ code: 'overbooking' })
        } else if (e.response.status === 413) {
          this.set_recent_booking_error({ code: 'file_too_large' })
        } else {
          this.set_recent_booking_error({ message: e.message })
          throw e
        }
      }
    },

    async upload_public_checkins_photo(payload) {
      const url = `${use_tenant().api_home_url}/checkins/upload_photo`
      try {
        const { data } = await Vue.smcb_axios.post(url, payload)
        return data
      } catch (e) {
        const error = { error: true, response: e.response, code: 'unknown' }
        if (e.response.status === 413) error.code = 'file_too_large'
        throw error
      }
    },

    async delete_public_checkins_photo(payload) {
      const url = `${use_tenant().api_home_url}/checkins/delete_photo`
      const response = await Vue.smcb_axios.delete(url, { data: payload })
      return response.data
    },

    clear_booking_data() {
      this.current_slot_defs = null
      this.slots = {}
      this.recent_booking_data = null
    },

    async load() {
      const use = { tenant: use_tenant(), public_actor: use_public_actor() }
      const { data } = await Vue.smcb_axios.get(`${use.tenant.api_home_url}/checkins/public`)

      use.tenant.options = data.active_gym_options
      use.tenant.services = data.services
      use.tenant.service_prices = data.service_prices
      use.tenant.settings = data.gym_settings
      use.tenant.home = data.gym
      use.tenant.is_german_pharma = data.is_german_pharma_location

      use.public_actor.is_employee = data.is_employee || false
      this.slot_areas_by_service = data.slot_areas_by_service
      this.days_in_advance_by_service = data.days_in_advance_by_service
    },
  },

  getters: {
    get_todays_public_slots: state => {
      const slots = Object.values(state.slots)
      return Vue.$vl_utils.sort_by(slots, x => x.check_in_at)
    },

    get_slot_by_datetime: () =>
      function (datetime) {
        return this.get_todays_public_slots.find(slot => slot.check_in_at.hasSame(datetime, 'minute'))
      },

    get_slot_area: state => slot_area_id => {
      return state.slot_areas[slot_area_id]
    },

    list_public_slot_areas: state => () => {
      return Object.values(state.slot_areas)
        .filter(slot_area => slot_area.archived_at === null)
        .sort((a, b) => a.order - b.order)
    },

    get_slot_defs_by_day: state => (state.slot_defs_by_day.loading ? {} : state.slot_defs_by_day),

    slot_def_by_id: state => def_id => state.current_slot_defs?.find(sd => sd.id == def_id),

    smcb_service_url: state => {
      return `${use_tenant().api_home_url}/services/${state.selected_service_id}`
    },

    selected_service(state) {
      const services = use_tenant().services
      return services && services.find(s => s.id === state.selected_service_id)
    },
  },
})
