import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_tenant } from '@public/stores/tenant_store'

function parse_slot(slot) {
  slot.check_in_at = Vue.$vl_time.parse_as_local(slot.check_in_at)
  return slot
}

export const use_octopus_tentacles = defineStore('octopus_tentacles', {
  state: () => ({
    tentacles: {},
  }),

  actions: {
    add_cluster_tentacle_slots({ vl_gym_id, slots }) {
      if (slots) {
        slots = slots.map(slot => ({ ...slot, slot: parse_slot(slot.slot) }))
      }
      const previous = this.tentacles[vl_gym_id].slots || []
      Vue.set(this.tentacles[vl_gym_id], 'slots', [...previous, ...slots])
    },

    set_cluster_tentacle_slots({ vl_gym_id, slots }) {
      if (slots) {
        slots = slots.map(slot => ({ ...slot, slot: parse_slot(slot.slot) }))
      }
      Vue.set(this.tentacles[vl_gym_id], 'slots', slots)
    },

    set_searching_service_for_all_tentacles(service_name) {
      Object.values(this.tentacles).forEach(tentacle => {
        const service = service_name === '*' ? tentacle.services[0] : tentacle.services.find(s => s.name === service_name)
        let slot_areas = []
        if (service) slot_areas = tentacle.slot_areas_by_service[service.id] || []
        Vue.set(tentacle, 'slot_areas', slot_areas)
      })
    },

    async setup_octopus_tentacle({ vl_gym_id, slug }) {
      Vue.set(this.tentacles, vl_gym_id, { id: vl_gym_id, slug })
      const url = use_tenant().api_url + `/gyms/${vl_gym_id}/checkins/public`
      const { data } = await Vue.smcb_axios.get(url)
      Vue.set(this.tentacles[vl_gym_id], 'smcb_gym', data.gym)
      Vue.set(this.tentacles[vl_gym_id], 'services', data.services)
      Vue.set(this.tentacles[vl_gym_id], 'smcb_gym_settings', data.gym_settings)
      Vue.set(this.tentacles[vl_gym_id], 'slot_areas_by_service', data.slot_areas_by_service)
    },

    wildcard_service_name(vl_gym_id, service_name) {
      if (service_name === '*') {
        const tentacle = this.tentacles[vl_gym_id]
        const services = tentacle?.services || []
        return services ? services[0].name : null
      }
      return service_name
    },

    async fetch_tentacle_slots_for_every_area({ vl_gym_id, service_name, date }) {
      service_name = this.wildcard_service_name(vl_gym_id, service_name)
      this.set_cluster_tentacle_slots({ vl_gym_id, slots: null })
      const date_path = date.toFormat('yyyy/MM/dd')
      const url = use_tenant().api_url + `/gyms/${vl_gym_id}/checkins/octopus-tentacle/${service_name}/${date_path}`
      try {
        const { data } = await Vue.smcb_axios.get(url)
        this.add_cluster_tentacle_slots({ vl_gym_id, slots: data.slots })
      } catch (e) {
        if (e.response.status === 404) this.set_cluster_tentacle_slots({ vl_gym_id, slots: [] })
      }
    },

    async fetch_tentacle_slots_for_area({ vl_gym_id, service_name, slot_area_name, date }) {
      service_name = this.wildcard_service_name(vl_gym_id, service_name)
      this.set_cluster_tentacle_slots({ vl_gym_id, slots: null })
      const slot_area = this.get_octopus_tentacle_slot_area({ vl_gym_id, slot_area_name })
      if (!slot_area) {
        console.info(`TENTACLE ${vl_gym_id} MISSES AREA ${slot_area_name}`)
        this.set_cluster_tentacle_slots({ vl_gym_id, slots: [] })
        return
      }

      const date_path = date.toFormat('yyyy/MM/dd')
      const url = use_tenant().api_home_url + `/gyms/${vl_gym_id}/checkins/octopus-tentacle/${service_name}/${date_path}?slot_area_id=${slot_area.id}`
      try {
        const { data } = await Vue.smcb_axios.get(url)
        this.set_cluster_tentacle_slots({ vl_gym_id, slots: data.slots })
      } catch (e) {
        if (e.response.status === 404) this.set_cluster_tentacle_slots({ vl_gym_id, slots: [] })
      }
    },

    fetch_checkin_cluster_slots({ slot_area, date }) {
      this.list_octopus_tentacles.forEach(tentacle => {
        const [service_name, slot_area_name] = slot_area.name.split('|')
        if (slot_area_name === 'NO-AREAS-PLEASE') {
          this.fetch_tentacle_slots_for_every_area({ vl_gym_id: tentacle.id, service_name, date })
        } else {
          this.fetch_tentacle_slots_for_area({ vl_gym_id: tentacle.id, service_name, slot_area_name, date })
        }
      })
    },
  },

  getters: {
    get_octopus_tentacle_smcb_gym:
      state =>
      ({ vl_gym_id }) => {
        const tentacle = state.tentacles[vl_gym_id]
        return (tentacle && tentacle.smcb_gym) || null
      },

    get_octopus_tentacle_slot_area:
      state =>
      ({ vl_gym_id, slot_area_name }) => {
        const tentacle = state.tentacles[vl_gym_id]
        return (tentacle && tentacle.slot_areas && tentacle.slot_areas.find(x => x.name === slot_area_name)) || null
      },

    list_octopus_tentacles(state) {
      return Object.values(state.tentacles).filter(x => !!x.smcb_gym)
    },

    get_octopus_tentacle_logo_url: () =>
      function (vl_gym_id) {
        const cf = use_tenant().cloudfront
        if (!cf) return null
        const smcb_gym_settings = this.get_octopus_tentacle_smcb_gym_settings(vl_gym_id)
        const logo_path = smcb_gym_settings?.logo_path || null
        if (!logo_path) return null
        return `${cf}${logo_path}.jpg`
      },

    get_octopus_tentacle_smcb_gym_settings: state => vl_gym_id => {
      const tentacle = state.tentacles[vl_gym_id]
      return (tentacle && tentacle.smcb_gym_settings) || null
    },

    get_octopus_tentacle_slug: state => vl_gym_id => {
      const tentacle = state.tentacles[vl_gym_id]
      return (tentacle && tentacle.slug) || null
    },

    get_octopus_tentacle_slots: state => vl_gym_id => {
      const tentacle = state.tentacles[vl_gym_id]
      return (tentacle && tentacle.slots) || null
    },

    are_all_tentacles_set: state => {
      return Object.values(state.tentacles).filter(x => !x.smcb_gym).length === 0
    },

    custom_service_title() {
      let name_title = {}
      this.list_octopus_tentacles
        .map(t => t.services.filter(s => s.name.startsWith('custom-')))
        .flat()
        .forEach(c => (name_title[c.name] = c.title_i18n[Vue.$i18n.locale || 'en']))
      return name_title
    },
  },
})
