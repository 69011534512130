import { defineStore } from 'pinia'
import Vue from 'vue'

export const use_tenant = defineStore('tenant', {
  state: () => ({
    api_url: null,
    cloudfront: null,
    default_client_id: null,
    home: null,
    is_german_pharma: null,
    modules: null,
    options: null,
    service_prices: null,
    services: null,
    settings: null,
    show_menu_bar: false,
    show_menu_bar_calendar_link: false,

    contextual: {
      service: null,
      service_options: null,
      service_settings: null,
    },
  }),

  actions: {
    write_modules(modules) {
      this.modules = Vue.$vl_utils.index_by(modules || [], 'module')
    },

    async load_minimal() {
      const url = `${this.api_home_url}/public-minimal`
      const { data } = await Vue.smcb_axios.get(url)
      this.home = data.gym
      this.settings = data.gym_settings
    },
  },

  getters: {
    loaded: state => !!state.home?.id, // TODO: NQ-3101 make more reliable
    api_home_url: state => `${state.api_url}/gyms/${state.home.vl_gym_id}`,

    get_service_settings: state => {
      const s = JSON.parse(JSON.stringify(state.contextual.service_settings || {}))
      const days_in_advance = parseInt(s.days_in_advance)
      s.days_in_advance = isNaN(days_in_advance) ? 7 : days_in_advance // Respect 0
      s.max_people_booking = parseInt(s.max_people_booking) || 1
      s.cancellation_notice = s.cancellation_notice || '-:minutes'
      s.new_slot_publish_time = s.new_slot_publish_time || '00:00'
      return s
    },

    coursebooking_settings: state => {
      const module = state.modules.coursebooking
      const data = module?.data || {}
      data.coursebooking = data.coursebooking || {}
      const days_in_advance = parseInt(data.coursebooking.days_in_advance)
      data.coursebooking.days_in_advance = isNaN(days_in_advance) ? 7 : days_in_advance // Respect 0
      data.coursebooking.max_people_booking = parseInt(data.coursebooking.max_people_booking) || 1
      return data.coursebooking
    },

    show_duration_of_stay: state => {
      if (!Vue.$vl_utils.is_running_noq()) return true
      if (state.options.show_duration_of_stay) return true
      return false
    },
  },
})
