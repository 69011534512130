import { DateTime } from 'luxon'
import { defineStore } from 'pinia'

import { use_public_tasks } from '@public/stores/public_tasks_store'

const parse = rtask => {
  return {
    ...rtask,
    start_date: DateTime.fromISO(rtask.start_date),
    end_date: rtask.end_date ? DateTime.fromISO(rtask.end_date) : rtask.end_date,
  }
}

export const use_public_recurring_tasks = defineStore('public_recurring_tasks', {
  state: () => ({
    all: [],
  }),

  actions: {
    add(recurring_tasks) {
      const new_rts = [...this.all, ...recurring_tasks.map(rtask => parse(rtask))]
      this.all = new_rts.filter((recurring_task, index) => new_rts.findIndex(rt => rt.id === recurring_task.id) === index)
    },
  },

  getters: {
    from_id: state => id => state.all.find(t => t.id === id),

    associated_tasks: () => id => use_public_tasks().active.filter(t => t.recurring_task_id === id),
  },
})
