import { defineStore } from 'pinia'
import axios from 'axios'
import Vue from 'vue'

import { use_public_actor } from '@public/stores/public_actor_store'
import { use_tenant } from '@public/stores/tenant_store'

function parse_participant_summary(ps) {
  ps.arrival_at = Vue.$vl_time.parse_as_local(ps.arrival_at)
  ps.checked_in_at = Vue.$vl_time.parse_as_local(ps.checked_in_at)
  return ps
}

export const use_documents = defineStore('documents', {
  state: () => ({
    integration_objects: {},
    is_pdf_ready: null, // null = loading
    is_set_up: false,
    page_error: {},
    page_is_broken: false,
    participant_short_token: null,
    participant_summary: {},
    pdf_was_deleted: null,
    pdfs: null,

    // TODO: NQ-2994 Namespace? New store?
    doc_uuid: null,
    pdf: null,
    ui_components: null,
    step: 'fill',
  }),

  actions: {
    async load(pstoken) {
      let pdf_urls

      const use = {
        tenant: use_tenant(),
        public_actor: use_public_actor(),
      }

      try {
        const { data } = await Vue.smcb_axios.post(`${use.tenant.api_url}/documents/${I18n.locale}`, { token: pstoken })

        use.public_actor.is_employee = data.is_employee

        use.tenant.home = data.gym
        use.tenant.settings = data.gym_settings
        use.tenant.contextual.service = data.service
        use.tenant.contextual.service_settings = data.service_settings

        this.participant_summary = parse_participant_summary(data.participant_summary)
        this.participant_short_token = pstoken

        pdf_urls = data.docs_info
      } catch (e) {
        console.error('SMCB Error', e)

        this.page_is_broken = true
        this.page_error = e
      }

      // We are done loading the page
      this.is_set_up = true

      // Now check if the PDF exists
      try {
        const { pdfs, deleted } = pdf_urls

        if (deleted) {
          this.pdf_was_deleted
        } else if (pdfs) {
          await pdfs.forEach(async pdf => {
            if (pdf.head) axios.head(pdf.head)
          })

          this.is_pdf_ready = true
          this.pdfs = pdfs
        }
      } catch (e) {
        console.error(e)
        this.is_pdf_ready = false
      }
    },

    async delete_result_pdf({ participant_id }) {
      const data = {
        id: participant_id,
        token: this.participant_short_token,
      }

      await Vue.smcb_axios.post(`${use_tenant().api_url}/documents/delete`, data)
      this.pdf_was_deleted = true
    },

    async set_participant_confirmed() {
      const url = `${use_tenant().api_url}/documents/confirm`
      const { data } = await Vue.smcb_axios.post(url, { token: this.participant_short_token })
      return data.confirmation
    },

    // NQ-2994: New file?

    async fetch_document_file(pstoken) {
      const url = `${use_tenant().api_home_url}/signatures/sign/${this.participant_summary.id}/${this.doc_uuid}/document/${pstoken}`
      const { data } = await Vue.smcb_axios.get(url)
      this.pdf = `data:application/pdf; base64,${encodeURI(data.pdf)}`
      this.ui_components = data.ui_components
    },

    async save_fill_out_data() {
      const payload = { components: this.ui_components, state: 'skip' }
      const url = `${use_tenant().api_home_url}/signatures/fill/${this.participant_summary.id}/${this.doc_uuid}`
      await Vue.smcb_axios.post(url, payload)

      // TODO: NQ-2994 process error here or on caller
      // try {
      // } catch (e) {
      // SignatureEmitter.set_signature_processing_error()
      // }
    },

    async commit_textbox(id, value) {
      const textbox_index = this.ui_components.textboxes.findIndex(t => t.field_id === id)
      if (textbox_index > -1) {
        Vue.set(this.ui_components.textboxes[textbox_index], 'value', value)
      }
    },

    async commit_checkbox(id, value) {
      const checkbox_index = this.ui_components.checkboxes.findIndex(c => c.field_id === id)
      if (checkbox_index > -1) {
        Vue.set(this.ui_components.checkboxes[checkbox_index], 'checked', value)
      }
    },
  },

  getters: {
    result_family: state => {
      switch (use_tenant().contextual.service.name) {
        case 'antigen':
        case 'pcr':
        case 'antibody':
          return 'test'
        case 'vax-covid':
        case 'vax-flu':
          return 'vax'
        case 'blood-pressure':
          return 'default'
      }
      return 'vax' // We use vax translations as the default until further notice
    },
  },
})
