import Vue from 'vue'
import SignatureEmitter from '@public/signatures/lib/signature_emitter'

import { defineStore } from 'pinia'
import { use_tenant } from './tenant_store'

const DEVICE_TOKEN = 'device-token'

export const use_public_devices = defineStore('public_devices', {
  // state: () => ({
  // device: {},
  // }),

  actions: {
    async update_device(device) {
      const url = `${use_tenant().api_home_url}/signatures/device`
      const { data } = await Vue.smcb_axios.post(url, device)
      // this.device = data
    },

    async init_device_token() {
      const stored_token = this.get_device_token()

      if (stored_token === null) {
        await SignatureEmitter.set_device_disconnected_error()
      } else {
        Vue.smcb_axios.add_header('device-token', stored_token)
      }
    },

    check_device_token() {
      return this.get_device_token() !== null
    },

    set_device_token(token) {
      localStorage.setItem(DEVICE_TOKEN, token)
      Vue.smcb_axios.add_header('device-token', token)
    },

    get_device_token() {
      return localStorage.getItem(DEVICE_TOKEN)
    },

    remove_device_token() {
      localStorage.removeItem(DEVICE_TOKEN)
    },
  },
})
