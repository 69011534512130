import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_tenant } from '@public/stores/tenant_store'
import localisation_options from '@core/lib/localisation_options'

const default_currency_format = localisation_options.currency_format.find(c => c.text === 'EUR')

export const use_public_payments = defineStore('public_payments', {
  state: () => ({
    merchant_data: null,
  }),

  actions: {
    async load() {
      let success = false
      try {
        const { data } = await Vue.smcb_axios.get(`${use_tenant().api_home_url}/payments/public`)
        if (data) {
          this.merchant_data = data.merchant_data
          success = true
        }
      } catch (error) {
        console.error(error)
      }
      return success
    },
  },

  getters: {
    currency_format: state => {
      if (state.merchant_data?.currency_code) {
        const currency_format = localisation_options.currency_format.find(c => c.text === state.merchant_data.currency_code)
        if (currency_format) return currency_format
      }
      return use_tenant().settings?.currency_format || default_currency_format
    },

    formatted_ticket_price: () =>
      function (price) {
        return Vue.localisation.format_price({ currency_format: this.currency_format }, price)
      },

    onboarding_complete: state => state.merchant_data?.onboarding_status === 'onboarded',
  },
})
