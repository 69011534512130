import { defineStore } from 'pinia'

import { use_public_task_groups } from '@public/stores/public_task_groups_store'
import { use_public_tasks } from '@public/stores/public_tasks_store'

export const use_public_calendar_filter = defineStore('public_calendar_filter', {
  state: () => ({
    task_group_ids: [],
  }),

  actions: {
    write_task_groups_ids(ids) {
      this.task_group_ids = [...ids].sort()
    },

    activate_task_groups({ task_group_ids, router }) {
      let ids = [...task_group_ids].sort()
      this.write_task_groups_ids(ids)
      ids = ids.join(',') || null
      router.replace({ params: { task_group_ids: ids } })
    },
  },

  getters: {
    is_task_hidden: state => task => {
      if (state.task_group_ids.length) {
        const task_group = use_public_task_groups().from_id(task.task_group_id)
        const is_active = state.task_group_ids.length === 0 || state.task_group_ids.includes(task.task_group_id)
        if (!task_group?.show_on_calendar) return true
        if (!is_active) return true
      }

      return false
    },

    filtered_tasks() {
      return use_public_tasks().active.filter(task => !this.is_task_hidden(task))
    },
  },
})
