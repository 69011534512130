<template>
  <div class="dropdown">
    <a
      id="dropdown-menu-button"
      class="dropdown-toggle d-flex align-items-center"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <vl-icon icon="md_language" />
      <span class="px-2">{{ name_of_current || $t('sidemenu.language') }}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-menu-button" style="z-index: 10000">
      <a v-for="(name, code) in languages" :key="code" :href="url(code)" class="dropdown-item">{{ name }}</a>
    </div>
  </div>
</template>
<script>
import VlIcon from '@core/shared/components/vl_icon.vue'

const language_db = {
  en: 'English',
  de: 'Deutsch',
  it: 'Italiano',
  ja: '日本語',
  nl: 'Nederlands',
  es: 'Español',
  fr: 'Français',
  cs: 'Česky', // Czech
  tr: 'Türkçe', // Turkish
  pl: 'Polski', // Polish
  ro: 'Limba română', // Romanian
  sk: 'Slovenčina', // Slovak
  sl: 'Slovenščina', // Slovene
  uk: 'Українська', // Ukrainian
  ru: 'Русский', // Russian
  // 'bg': 'Български (Bulgarian)',
  // 'hu': 'Magyar (Hungarian)',
  // 'lt': 'Lietuvių', // Lithuanian
  // 'lv': 'Latviešu', // Latvian
  // 'sq': 'Shqip', // Albanian
  // 'sr': 'Српски', // Serbian
}

export default {
  computed: {
    languages() {
      return language_db
    },

    name_of_current() {
      return language_db[this.$i18n.locale]
    },
  },

  methods: {
    url(code) {
      const url = window.location
      let path = url.pathname
      path = path.replace(`/${this.$i18n.locale}`, '')
      path = `/${code}` + path
      return url.origin + path + url.search + url.hash
    },
  },

  components: {
    VlIcon,
  },
}
</script>
