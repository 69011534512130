<template>
  <b-overlay :show="show" spinner-variant="primary" no-wrap :opacity="1.0">
    <template #overlay>
      <div class="text-center">
        <b-spinner class="mb-3" style="width: 5rem; height: 5rem" variant="secondary" />
        <h3 id="cancel-label">{{ loading_text }}</h3>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { BOverlay, BSpinner } from 'bootstrap-vue'
import { eventBus } from '@core/shared/event_bus'

// TODO: can be replaced with a Vue watcher
export const SHOW_LOADING = 'show_loading'

export default {
  name: 'SignatureLoader',

  mounted() {
    if (this.always_on) {
      this.show = true
      if (this.timeout) this.timeout_schedule = setTimeout(this.handle_timeout, 1000 * this.timeout)
    } else {
      eventBus.$on(SHOW_LOADING, value => {
        this.show = value
        clearTimeout(this.timeout_schedule)
        if (this.show && this.timeout) this.timeout_schedule = setTimeout(this.handle_timeout, 1000 * this.timeout)
      })
    }
  },

  destroyed() {
    clearTimeout(this.timeout_schedule)
  },

  props: {
    loading_text: String,
    always_on: {
      type: Boolean,
      default: false,
    },

    timeout: {
      type: Number,
      default: 30,
    },
  },

  data() {
    return {
      show: false,
      timeout_schedule: null,
    }
  },

  methods: {
    handle_timeout() {
      this.$vl_utils.show_error_alert(this.$i18n.t('signature.alert.load_timeout'))
      this.$vl_utils.show_error_alert(this.loading_text)
      this.use.public_signatures.reset_page()
    },
  },

  components: {
    BOverlay,
    BSpinner,
  },
}
</script>

<style scoped>
.bd-example-modal-lg .modal-dialog {
  display: table;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 24px);
}

.bd-example-modal-lg .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
}
</style>
