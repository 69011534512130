import { defineStore } from 'pinia'

export const use_public_task_groups = defineStore('public_task_groups', {
  state: () => ({
    all: [],
  }),

  actions: {
    set(task_groups) {
      this.all = task_groups.map(Object.freeze)
    },
  },

  getters: {
    active: state => state.all.filter(tg => !tg.archived_at),
    from_id: state => id => state.all.find(t => t.id === id),
  },
})
