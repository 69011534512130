import { defineStore } from 'pinia'
import Vue from 'vue'

export const use_public_time_range = defineStore('public_time_range', {
  state: () => ({
    fr: '00:00',
    to: '24:00',
  }),

  getters: {
    public_checkins_time_range: state => ({ fr: state.fr, to: state.to }),

    public_checkins_using_time_range: state => {
      return state.fr !== '00:00' || state.to !== '24:00'
    },

    public_checkins_time_range_values: () => [...Array(25).keys()].map(x => x.toString().padStart(2, '0') + ':00'),
  },
})
