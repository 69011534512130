import { eventBus } from '@core/shared/event_bus'

export const PROCESSING_ERRORS = 'processing_error'
export const WEBSOCKET_ERRORS = 'websocket_error'
export const DEVICE_DISCONNECTED = 'device_disconnected'

class SignatureEmitter {
  static set_signature_processing_error() {
    eventBus.$emit(PROCESSING_ERRORS)
  }

  static set_signature_websocket_error() {
    eventBus.$emit(WEBSOCKET_ERRORS)
  }

  static set_device_disconnected_error() {
    eventBus.$emit(DEVICE_DISCONNECTED)
  }
}

export default SignatureEmitter
